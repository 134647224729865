.background {
  background: url('./Assets/backgroundPhoto.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: '100%';
  margin: auto;
  max-width: 2500px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.whiteTextBackground {
  background-color: rgba(236, 236, 236, 0.8);
  padding: 5px;
  border-radius: 10px;
}

.tanBackground {
  background-color: #D6C08A;
  padding: 5px;
  border-radius: 10px;
}

.EBGaramond {
  font-family: 'EB Garamond Variable', sans-serif;
}

.merriweather {
  font-family: 'Merriweather', sans-serif;
}

#whiteTextBackground {
  background-color: rgba(236, 236, 236, 0.8);
  padding: 5px;
  border-radius: 10px;
}